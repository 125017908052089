import React from "react"
import { navigate } from "gatsby"
import { auth, googleAuthProviderId } from '../../config/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { setUser, isLoggedIn } from "../../helpers/auth"

const Login = () => {

  if (isLoggedIn()) {
    navigate(`/admin/login`, { replace: true })
  }

  function getUiConfig(signInOptions) {
    return {
      signInFlow: 'popup',
      signInOptions: signInOptions,
      // signInSuccessUrl: '/admin',
      callbacks: {
        signInSuccessWithAuthResult: (result) => {
          setUser(result.user);
          navigate(`/admin`, { replace: true })
        }
      }
    };
  }
  
  return (
    <div title="Log In">
      <p>Please sign-in:</p>
      <StyledFirebaseAuth uiConfig={getUiConfig([googleAuthProviderId])} firebaseAuth={auth}/>
    </div>
  );

}

export default Login
